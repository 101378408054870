@import "@/theme.scss";



































































































































































































































































.arcprogress {
  position: relative;
  // background: black;
  background: #191919;

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .titlebox {
    position: absolute;

    left: 10px;
    top: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 16px;
      color: #b4b4b4;
      font-weight: bold;
    }
    .notice {
      font-size: 12px;
      color: #b4b4b4;
      margin-left: 20px;
      cursor: pointer;
      .el-button {
        width: 16px !important;
        height: 16px !important;
        line-height: 16px;
        color: #6d6d6d;
        border-radius: 50%;
        border: none;
        padding: 0;
        background: #3a3a3a;
      }
    }
  }
  .numbox {
    position: absolute;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    left: 30px;
    bottom: 10px;
    color: #b4b4b4;
    font-size: 12px;
    text-align: left;
    // margin-left: 20px;
    .left {
      .num {
        color: #33a8ee;
      }
    }
    .right {
      // margin-left: 40px;
      .percent {
        // font-size: 24px;
      }
      .up {
        color: #ff7373;
      }
      .blow {
        color: #40c5fd;
      }
    }
  }
  .shadow {
    text-align: center;
    .svgg {
      margin: 0 auto;
    }
  }
}
