@import "@/theme.scss";





































































































































.detailnews {
  overflow: auto;
  width: 100%;
  height: 400px;
  margin: 0 auto;
  // background: #191919;
  @include themeBg1();

  .newsbox {
    padding: 0 10px;
    li {
      display: flex;
      align-items: center;
      min-height: 70px;
      cursor: pointer;
      .left {
        width: 100px;
        text-align: center;

        .avr {
          border-radius: 50%;
          height: 40px;
          width: 40px;
          // background: #b4b4b4;
          border-radius: 50%;
          overflow: hidden;
          img {
            width: 40px;

            // border-radius: 50%;
          }
        }
      }
      .right {
        text-align: left;
        padding-right: 20px;
        flex: 1;
        .time {
          // color: #fdca64;
           @include news_fontColor2();

        }
        .content {
          // color: #b4b4b4;
          @include fontColor();

        }
        .from {
          // color: #b4b4b4;
          @include fontColor();
          text-align: right;
        }
      }
    }
    li:hover {
      cursor: pointer;
    }
  }
  .detailTips {
    // background: #222;
    // color: #fff;
    padding: 0 20px;
    text-indent: 40px;
    z-index: 10000;
    text-align: left;
    @include fontColor();

    @include themeBg1();

    .el-drawer__body{
      height:100%;
    }
    .el-drawer__close-btn {
      background: #a3a3a3;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      color: #fff;
    }
    .el-drawer__body {
      text-align: left;
    }
  }
}
.detailnews::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.detailnews::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(60, 50, 153, 0.2);
  background: #535353;
}
.detailnews::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 6px rgba(23, 10, 139, 0.2);
  border-radius: 10px;
  background: #ededed;
}
