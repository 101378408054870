@import "@/theme.scss";































































































































































































































































































































































































































































































































.detail {
  // color: #fff;
  padding: 0px 20px 40px 20px;
  // background: #222222;
  @include fontColor();
  @include themeBg();
  .back {
    color: #0ba3f0;
    font-size: 22px;
    height: 40px;
    line-height: 40px;
    text-align: left;
    .backbtn {
      display: inline-block;
      cursor: pointer;
    }
    .backbtn:hover {
      cursor: pointer;
    }
  }
  .el-col {
    text-align: center;
  }
  .companybox {
    p {
      margin-left: 20px;
    }
    .company {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        display: flex;
        align-items: center;
        height: 40px;
        border-bottom: 4px;
        border-color: #0ba3f1;
        .nodata {
          display: flex;
          align-items: center;
        }
        .data {
          display: flex;

          align-items: center;
        }
        .name {
          margin-left: 20px;
          font-size: 22px;
        }
        .symbol {
          font-size: 12px;
          color: #a7a7a7;
          margin-left: 20px;
        }
        .down {
          color: green;
        }
        .up {
          color: #ff4c4c;
        }
        .from {
          font-size: 16px;
          color: #a7a7a7;
        }
      }
      .right {
        display: flex;
        align-items: center;

        .subleft {
          display: flex;
          align-items: center;
          .ratio {
            display: flex;
            align-items: center;

            .tit {
              font-size: 22px;
            }
            .num {
              color: #4cd2ff;
              font-size: 22px;
              font-weight: bold;
            }
            .line {
              color: #0ba3f0;
            }
          }
          .numbers {
            display: flex;
            align-items: center;
            .tit {
              font-size: 22px;
            }
            .num {
              color: #ffdc72;
              font-size: 22px;
              font-weight: bold;
            }
          }
        }
        .subright {
          display: flex;
          align-items: center;

          .st {
            background: #0ba3f1;
            color: #fff;
            padding: 5px 10px;
            margin-left: 10px;
            margin-right: 10px;
          }
        }
      }
    }
    .bottom {
      height: 4px;
      background: #0ba3f1;
    }
  }
  .chart {
    .row {
      display: flex;
      .el-col {
        flex: 1;
        .el-row {
          display: flex;
          .el-col {
            flex: 1;
          }
        }
      }
    }
    .mg_top10 {
      margin-top: 10px;
    }
    .mg_left10 {
      margin-left: 10px;
    }
    .dropbox {
      display: inline-block;
      width: 20px;
      height: 20px;
      line-height: 20px;
      background: grey;
      text-align: center;
      color: #fff;
      .el-icon--right {
        margin: 0 !important;
      }
    }
    .el-col {
      .tit {
        text-align: left;
        height: 30px;
        line-height: 30px;
        width: 120px;
        margin-top: 10px;
        background: #0ba3f1;
        // color: black;
        text-align: center;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        @include fontColor4();
      }
    }
  }
}
